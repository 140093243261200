@font-face {
  font-family: "MyCustomFont";
  src: url("./fonts/font.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.section {
  margin-top: 15px;
}
.footer {
  margin-top: 20px;
  font-family: "MyCustomFont", sans-serif;
  font-size: 20px;
  color: #fff;
  text-align: center;
}

.footer a {
  color: #5271ff;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
.App {
  font-family: "MyCustomFont", sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.avatarCanvas {
  border-radius: 0px;
}
#originaleyes {
  background:
  url("https://i.imgur.com/nAjA3Y9.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#bigeyes {
  background:
    url("https://i.imgur.com/RUeP2wc.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#sus {
  background:
    url("https://i.imgur.com/of7XJpY.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#smail {
  background:
    url("https://i.imgur.com/tG9zDRs.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#smail5 {
  background:
    url("https://i.imgur.com/S9AiQXi.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#smail6 {
  background:
    url("https://i.imgur.com/NIgJnce.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#originalbody {
  background: url("https://i.imgur.com/aVJpnYd.png")
    0 0 / cover no-repeat;
}
#neonbody {
  background: url("https://i.imgur.com/0CKDWjM.png")
    0 0 / cover no-repeat;
}
#beerbody {
  background: url("https://i.imgur.com/nNdfL17.png")
    0 0 / cover no-repeat;
}
#grasssbody {
  background: url("https://i.imgur.com/KGpVZtB.png")
    0 0 / cover no-repeat;
}
#greenbody {
  background: url("https://i.imgur.com/Xut01b3.png")
    0 0 / cover no-repeat;
}
#redbody {
  background: url("https://i.imgur.com/bdA2v0Q.png")
    0 0 / cover no-repeat;
}
#starsbody {
  background: url("https://i.imgur.com/XyWfUHd.png")
    0 0 / cover no-repeat;
}
#yebody {
  background: url("https://i.imgur.com/bLH4NIz.png")
    0 0 / cover no-repeat;
}
#whitebody {
  background: url("https://i.imgur.com/sQ57iOf.png")
    0 0 / cover no-repeat;
}
.selectionPanel {
  max-width: 400px;
  width: 100%;
  background: #fff128;
  border: 2px solid rgba(0, 0, 0, 0.12);
  padding: 30px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  font-family: "MyCustomFont", sans-serif;
  margin: 0 auto;
}
h1 {
  font-size: 44px;
  text-transform: uppercase;
  font-family: "MyCustomFont", sans-serif;
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
.selectionbuttons {
  font-family: "MyCustomFont", sans-serif;
  width: 90px;
  height: 90px;
  margin-right: 5px;
  background-color: transparent;
  outline: none;
  border-radius: 6px;
  border: 1px solid #b9b9b9;
  margin-right: 10px;
}
.saveButton {
  font-family: "MyCustomFont", sans-serif;
  padding: 5px 18px;
  font-size: 3rem;
  letter-spacing: 2px;
  background-color: #fff128;
  border: 2px solid white;
  margin-top: 10px;
  color: white;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  cursor: pointer;
}
.selectionbuttons.selected {
  border: 2px solid white;
}
h3 {
  font-size: 25px;
  text-transform: uppercase;
  font-family: "MyCustomFont", sans-serif;
}
#bip {
  background:
    url("https://i.imgur.com/nE3m2wI.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#mhand {
  background:
    url("https://i.imgur.com/jTFm7iS.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#cape {
  background:
    url("https://i.imgur.com/jGriiGK.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#clothes4 {
  background:
    url("https://i.imgur.com/7fnssBy.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#clothes5 {
  background:
    url("https://i.imgur.com/5dJbVfB.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#clothes6 {
  background:
    url("https://i.imgur.com/WIesKpv.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#clothes7 {
  background:
    url("https://i.imgur.com/HTG5BJY.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#clothes8 {
  background:
    url("https://i.imgur.com/dJgHtKw.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#originalmouth {
  background:
    url("https://i.imgur.com/W1PDkYP.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#rabbitmouth {
  background:
    url("https://i.imgur.com/0cZ5H4N.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#glas {
  background:
    url("https://i.imgur.com/rQUSHJ8.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#glas4 {
  background:
    url("https://i.imgur.com/btlTaSj.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#glas5 {
  background:
    url("https://i.imgur.com/dMMj1W6.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#glas6 {
  background:
    url("https://i.imgur.com/GEQuHkh.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#cap {
  background:
    url("https://i.imgur.com/Wy870IK.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#headband {
  background:
    url("https://i.imgur.com/yD3oN3w.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#mooney {
  background:
    url("https://i.imgur.com/Tkibjs3.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#bm {
  background:
    url("https://i.imgur.com/6ps2t35.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#pf {
  background:
    url("https://i.imgur.com/jh22MAs.png")
      0 0 / cover no-repeat,
    url("https://i.imgur.com/nNdfL17.png")
      0 0 / cover no-repeat;
}
#bg1 {
  background: url("https://4z9y5p-3000.csb.app/static/media/Bg1.bc920655d1bd45286f80.png")
    0 0 / cover no-repeat;
}
#bg2 {
  background: url("https://meme.nuggetsolana.xyz/static/media/Bg2.3f0b05f9a9dcaf43a390.png")
    0 0 / cover no-repeat;
}
#bg3 {
  background: url("https://meme.nuggetsolana.xyz/static/media/Bg3.b9227666494819ce51ac.png")
    0 0 / cover no-repeat;
}
#bg4 {
  background: url("https://meme.nuggetsolana.xyz/static/media/Bg4.6e82f2ee30c3d79dae19.png")
    0 0 / cover no-repeat;
}
#bg5 {
  background: url("https://i.imgur.com/02MJXfG.jpeg")
    0 0 / cover no-repeat;
}
.selectionbuttons.selected {
  border: 3px solid white;
}
.content {
  display: flex;
  flex-direction: row;
  gap: 60px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.selectionPanel {
  flex: 1;
}

.emptyColumn {
  flex: 1;
}

.avatarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header {
  height: 50px;
}
body {
  padding: 40px 120px 80px;
  background-color: #eacf9f;
  background-attachment: fixed;
}
@media screen and (max-width: 768px) {
  .selectionPanel,
  .avatarContainer {
    max-width: 300px;
  }
  .selectionbuttons {
    height: 70px;
    width: 70px;
  }

  .saveButton {
    font-size: 24px;
    padding: 5px 10px;
  }
}

body {
  padding: 40px 20px 80px;
  background-color: #eacf9f;
  background-attachment: fixed;
}
